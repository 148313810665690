import React, { useState, useEffect } from "react";

import { useDispatch } from "react-redux";

import { themeItems } from "../../reducers/slices/themeApp.slice";
import { modulesViewItems } from "../../reducers/slices/modulesView.slice";
import { landingPageItems } from "../../reducers/slices/landingPage.slice";
import { singupConfigItems } from "../../reducers/slices/singupConfig.slice";
import { carouselItems } from "../../reducers/slices/carousel.slice";
import { plateformItems } from "../../reducers/slices/plateform.slice";
import { nbreUsersItems } from "../../reducers/slices/nbreUsers.slice";
import { nbreAbstractsItems } from "../../reducers/slices/nbreAbstracts.slice"; 

import defaultTheme from "../../dynamicValues/defaultTheme.json";

import axios from "axios";
import { USER_API_GET_SITEAPP_CONFIG } from "../../config";

export default function ParamsApp({ children }) {
  const [isLoading, setIsLoading] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isLoading) {
      axios
        .get(`${USER_API_GET_SITEAPP_CONFIG}`, {})
        .then((response) => {

          const plateform = Array.isArray(response.data.plateformItems)
            ? response.data.plateformItems[0]
            : response.data.plateformItems;

          const themeConfig = Array.isArray(response.data.themeConfigItems)
            ? response.data.themeConfigItems[0]
            : response.data.themeConfigItems;

          const modulesView = Array.isArray(response.data.modulesViewItems)
            ? response.data.modulesViewItems[0]
            : response.data.modulesViewItems;

          const landingPage = Array.isArray(response.data.landingPageItems)
            ? response.data.landingPageItems[0]
            : response.data.landingPageItems;

          const signupConfig = Array.isArray(response.data.singupConfigItems)
            ? response.data.singupConfigItems[0]
            : response.data.singupConfigItems;

          const carouselConfig = Array.isArray(response.data.carouselItems)
            ? response.data.carouselItems
            : [response.data.carouselItems];

          const nbreUsers = Array.isArray(response.data.nbreUsersItems)
            ? response.data.nbreUsersItems
            : response.data.nbreUsersItems;

          const nbreAbstracts = Array.isArray(response.data.nbreAbstractsItems)
            ? response.data.nbreAbstractsItems
            : response.data.nbreAbstractsItems;

          dispatch(themeItems(themeConfig));
          dispatch(modulesViewItems(modulesView));
          dispatch(landingPageItems(landingPage));
          dispatch(singupConfigItems(signupConfig));
          dispatch(carouselItems(carouselConfig));
          dispatch(plateformItems(plateform));
          dispatch(nbreUsersItems(nbreUsers));
          dispatch(nbreAbstractsItems(nbreAbstracts));

          setIsLoading(false);
        })
        .catch((error) => {
          dispatch(themeItems(defaultTheme));
          setIsLoading(false);
        });
    }
  }, [isLoading]);

  return !isLoading ? <>{children}</> : null;
}
