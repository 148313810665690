import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import { API_GET_LANGUES } from './config';

const getInitialLanguage = () => {
  const savedLanguage = localStorage.getItem('i18nextLng') || 'fr';
  return savedLanguage;
};

const loadLanguages = async () => {
  const response = await fetch(`${API_GET_LANGUES}languages.json`);
  return await response.json();
};

const loadNamespaces = async () => {
  const response = await fetch(`${API_GET_LANGUES}namespaces.json`);
  return await response.json();
};

const initI18n = async () => {
  try {
    const [languages, namespaces] = await Promise.all([loadLanguages(), loadNamespaces()]);

    await i18n
      .use(Backend)
      .use(initReactI18next)
      .init({
        lng: getInitialLanguage(),
        fallbackLng: 'fr',
        whitelist: languages,
        ns: namespaces,
        defaultNS: 'common',
        debug: false,
        react: {
          useSuspense: true, // Active Suspense
        },
        interpolation: {
          escapeValue: false, // React gère déjà l'échappement des caractères
        },
        backend: {
          loadPath: `/translation/translations/{{lng}}/{{ns}}`,
        },
      });
  } catch (error) {
    console.error('Erreur lors du chargement des langues ou namespaces :', error);
  }
};

export { initI18n, i18n };
