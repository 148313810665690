import React, { startTransition, useCallback, useEffect, useState } from 'react'
import socket from './Socket'

import { notification } from 'antd';

import { themeItems } from '../reducers/slices/themeApp.slice';
import { landingPageItems } from '../reducers/slices/landingPage.slice';
import { carouselItems } from '../reducers/slices/carousel.slice';
import { modulesViewItems } from '../reducers/slices/modulesView.slice';
import { plateformItems } from '../reducers/slices/plateform.slice';

import { useDispatch } from 'react-redux';
import axios from 'axios';
import { API_USER_GETCAROUSELELEMENTS } from '../config';
import { useTranslation } from 'react-i18next';
import { i18n } from '../i18n';
import { singupConfigItems } from '../reducers/slices/singupConfig.slice';
import { programItems } from '../reducers/slices/program.slice';

socket.connect();
window.socketUser = socket;

export default function SocketInterceptor({ children }) {

    const dispatch = useDispatch();
    const [notificationApi, contextHolderNotification] = notification.useNotification();

    const [currentLanguage, setCurrentLanguage] = useState(i18n.language);

    useEffect(() => { setCurrentLanguage(i18n.language) }, [i18n.language]);



    useEffect(() => {

        const handleNotification = (data) => {
            notificationApi.open({
                message: data.title,
                description: data.message,
                placement: 'topRight',
            });
        }

        const handleSetSkin = (data) => {
            dispatch(themeItems(data));
        }
        const handleLogoDesktop = (data) => {

            const updateLogoDesktop = {
                logoDesktop: data
            };
            dispatch(landingPageItems(updateLogoDesktop));
        };
        const handleLogoMobile = (data) => {
            const updateLogoMobile = {
                logoMobile: data
            };
            dispatch(landingPageItems(updateLogoMobile));
        };
        const handleCarouseChanged = () => {

            axios.get(`${API_USER_GETCAROUSELELEMENTS}`)
                .then(response => { dispatch(carouselItems(response.data.carouselItems)) })
                .catch(error => { })
        }
        const handleContactInfos = (data) => {
            const updateContactInfos = {
                googleMapsLink: data.googleMapsLink,
                adress: data.adress,
                mailContact: data.mailContact,
                phoneContact: data.phoneContact
            };
            dispatch(landingPageItems(updateContactInfos));
        };
        const handleSocialMedias = (data) => {
            const updateSocialMedias = {
                socialMedias: data
            };
            dispatch(landingPageItems(updateSocialMedias));
        };
        const handleTextPresident = (data) => {
            const updateTextPresident = {
                titleTextPresident: data.titleTextPresident,
                textPresident: data.textPresident,
                textPresidentSource: data.textPresidentSource
            };
            dispatch(landingPageItems(updateTextPresident));
        };
        const handleTextPresidentTwo = (data) => {
            const updateTextPresident = {
                titleTextPresidentTwo: data.titleTextPresidentTwo,
                textPresidentTwo: data.textPresidentTwo,
                textPresidentTwoSource: data.textPresidentTwoSource
            };
            dispatch(landingPageItems(updateTextPresident));
        };
        const handleTeamCat = (data) => {
            const updateTeamCat = {
                teamCat: data.teamCat
            }
            dispatch(landingPageItems(updateTeamCat));
        };
        const handleTeam = (data) => {
            const teamUpdate = {
                team: data
            };
            dispatch(landingPageItems(teamUpdate));
        };
        const handleSponsorsCat = (data) => {
            dispatch(landingPageItems(data));
        };
        const handleSponsors = (data) => {
            const updatedSponsor = {
                sponsors: data
            };
            dispatch(landingPageItems(updatedSponsor));
        };
        const handlePartnersCat = (data) => {
            dispatch(landingPageItems(data));
        };
        const handlePartners = (data) => {
            const updatedSponsor = {
                partners: data
            };
            dispatch(landingPageItems(updatedSponsor));
        };
        const handleInfosBar = (data) => {
            dispatch(landingPageItems(data));
        };
        const handleThemes = (data) => {
            dispatch(landingPageItems(data));
        };
        const handleCounter = (data) => {
            const updatedCounter = {
                counter: data
            };
            dispatch(landingPageItems(updatedCounter));
        };
        const handleModulesView = (data) => {
            dispatch(modulesViewItems(data));
        }
        const handleTranslations = () => {
            i18n.reloadResources().then(() => {
                i18n.changeLanguage(currentLanguage).finally(() => {
                });
            }).catch(error => {
                console.error("Error reloading resources:", error);
            });
        }
        const handleTranslationsLng = (data) => {

            dispatch(plateformItems(data));

            if (data.langues.includes(currentLanguage)) {
                i18n.reloadResources().then(() => {
                    i18n.changeLanguage(currentLanguage).finally(() => {
                    });
                }).catch(error => {
                    console.error("Error reloading resources:", error);
                });
            } else {
                localStorage.setItem('i18nextLng', data.langues[0]);
                i18n.reloadResources().then(() => {
                    i18n.changeLanguage(data.langues[0]).finally(() => {
                    });
                }).catch(error => {
                    console.error("Error reloading resources:", error);
                });

            }

        }


        const handlelabos = (data) => {
            const updateLabos = {
                labos: data.labos
            }
            dispatch(singupConfigItems(updateLabos));
        };


        const handleSingupRoles = (data) => {
            const updateSingupRoles = {
                roles: data.roles
            }
            dispatch(singupConfigItems(updateSingupRoles));
        };

        const handleSingupHonorific = (data) => {
            const updateSingupHonorific = {
                honorifics: data.honorifics
            }
            dispatch(singupConfigItems(updateSingupHonorific));
        };

        const handleSingupProClass = (data) => {
            const updateSingupProClass = {
                proClass: data.proClass
            }
            dispatch(singupConfigItems(updateSingupProClass));
        };

        const handleSingupConfig = (data) => {
            const updateSingupConfig = {
                singupActive: data.singupActive,
                pack: data.pack,
                payement: data.payement,
                accountActive: data.accountActive,
                priseEnCharge: data.priseEnCharge,
                dateLimit: data.dateLimit
            };
            dispatch(singupConfigItems(updateSingupConfig));
        };


        const handlePackListCat = (data) => {
            const updatePackListCat = {
                packListCat: data.packListCat
            }
            dispatch(singupConfigItems(updatePackListCat));
        };

        const handlePackList = (data) => {
            const updatePackList = {
                packList: data.packList,
                packDescription: data.packDescription
            }
            dispatch(singupConfigItems(updatePackList));
        };

        const handleHotels = (data) => {
            const updateHotels = {
                hotelList: data.hotelList
            }
            dispatch(singupConfigItems(updateHotels));
        };

        const handleRoomsList = (data) => {
            console.log(data)
            const updateRoomsList = {
                roomsList: data.roomsList,
            }
            dispatch(singupConfigItems(updateRoomsList));
        };

        const handlePaymentList = (data) => {
            const updatePaymentList = {
                payementList: data,
            }
            dispatch(singupConfigItems(updatePaymentList));
        };

        const handlePaymentHotelList = (data) => {
            const updatePaymentHotelList = {
                payementHotelList: data,
            }
            dispatch(singupConfigItems(updatePaymentHotelList));
        };


        const handleProgram = (data) => {
            const programUpdate = {
                programItems: data
            };
            dispatch(programItems(programUpdate));
        };

        socket.on('notification', handleNotification);
        socket.on('setSkin', handleSetSkin);
        socket.on('logoDesktop', handleLogoDesktop);
        socket.on('logoMobile', handleLogoMobile);
        socket.on('carouselChanged', handleCarouseChanged);
        socket.on('contactInfos', handleContactInfos);
        socket.on('socialMedias', handleSocialMedias);
        socket.on('textPresident', handleTextPresident);
        socket.on('textPresidentTwo', handleTextPresidentTwo);
        socket.on('teamCat', handleTeamCat);
        socket.on('team', handleTeam);
        socket.on('sponsorsCat', handleSponsorsCat);
        socket.on('sponsors', handleSponsors);
        socket.on('partnersCat', handlePartnersCat);
        socket.on('partners', handlePartners);
        socket.on('infosBar', handleInfosBar);
        socket.on('themes', handleThemes);
        socket.on('counter', handleCounter);
        socket.on('moduleView', handleModulesView);
        socket.on('setTranslations', handleTranslations);
        socket.on('setTranslationsLng', handleTranslationsLng);
        socket.on('labos', handlelabos);
        socket.on('SingupRoles', handleSingupRoles);
        socket.on('SingupHonorific', handleSingupHonorific);
        socket.on('SingupProClass', handleSingupProClass);
        socket.on('singupConfig', handleSingupConfig);
        socket.on('packCat', handlePackListCat);
        socket.on('pack', handlePackList);
        socket.on('hotels', handleHotels);
        socket.on('rooms', handleRoomsList);
        socket.on('payment', handlePaymentList);
        socket.on('paymentHotel', handlePaymentHotelList);
        socket.on('programItems', handleProgram);


        return () => {
            socket.off('notification', handleNotification);
            socket.off('setSkin', handleSetSkin);
            socket.off('logoDesktop', handleLogoDesktop);
            socket.off('logoMobile', handleLogoMobile);
            socket.off('carouselChanged', handleCarouseChanged);
            socket.off('contactInfos', handleContactInfos);
            socket.off('socialMedias', handleSocialMedias);
            socket.off('textPresident', handleTextPresident);
            socket.off('textPresidentTwo', handleTextPresidentTwo);
            socket.off('teamCat', handleTeamCat);
            socket.off('team', handleTeam);
            socket.off('sponsorsCat', handleSponsorsCat);
            socket.off('sponsors', handleSponsors);
            socket.off('partnersCat', handlePartnersCat);
            socket.off('partners', handlePartners);
            socket.off('infosBar', handleInfosBar);
            socket.off('themes', handleThemes);
            socket.off('counter', handleCounter);
            socket.off('moduleView', handleModulesView);
            socket.off('setTranslations', handleTranslations);
            socket.off('setTranslationsLng', handleTranslationsLng);
            socket.off('labos', handlelabos);
            socket.off('SingupRoles', handleSingupRoles);
            socket.off('singupConfig', handleSingupConfig);
            socket.off('packCat', handlePackListCat);
            socket.off('pack', handlePackList);
            socket.off('hotels', handleHotels);
            socket.off('rooms', handleRoomsList);
            socket.off('payment', handlePaymentList);
            socket.off('paymentHotel', handlePaymentHotelList);
            socket.off('programItems', handleProgram);

        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [socket]);

    return (
        <>
            {contextHolderNotification}
            {children}
        </>
    )
}
